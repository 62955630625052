<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(createNewProduct)">
                    <h3 class="mb-10 font-weight-bold text-dark">
                      Warehouse Product:
                    </h3>
                    <div class="row">
                      <div class="col-xl-12">
                        <validation-provider rules="required|clientNotEmpty" name="Client" v-slot="{ valid, errors }">
                          <b-form-group
                            label-cols-sm="3"
                            id="input-group-1"
                            label="Belong to Client"
                            label-for="input-1"
                          >
                            <b-form-select
                              id="input-3"
                              v-model="belongTo"
                              :options="clientOptions"
                              :state="errors[0] ? false : valid ? true : null"
                              class="form-control form-control-lg form-control-solid"
                            ></b-form-select>
                            <b-form-invalid-feedback id="inputLiveFeedback">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider rules="required" name="sku" v-slot="{ valid, errors }">
                          <b-form-group label-cols-sm="3" id="input-group-1" label="SKU" label-for="input-1">
                            <b-form-input
                              v-model="sku"
                              id="input-1"
                              type="text"
                              name="sku"
                              class="form-control form-control-lg form-control-solid"
                              :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback id="inputLiveFeedback">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider rules="required" name="name" v-slot="{ valid, errors }">
                          <b-form-group label-cols-sm="3" id="input-group-1" label="Product" label-for="input-2">
                            <b-input-group size="lg">
                              <b-form-input
                                v-model="name"
                                id="input-2"
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                name="name"
                                :state="errors[0] ? false : valid ? true : null"
                              ></b-form-input>
                              <b-form-invalid-feedback id="inputLiveFeedback">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Description" label-for="input-3">
                          <b-form-input
                            v-model="description"
                            id="input-3"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="description"
                          ></b-form-input>
                        </b-form-group>

                        <validation-provider rules="required" name="total_qty" v-slot="{ valid, errors }">
                          <b-form-group
                            label-cols-sm="3"
                            id="input-group-1"
                            label="Quantity on hand"
                            label-for="input-3"
                          >
                            <b-form-input
                              v-model="totalQty"
                              id="input-3"
                              type="number"
                              class="form-control form-control-lg form-control-solid"
                              name="totalQty"
                              :state="errors[0] ? false : valid ? true : null"
                            ></b-form-input>
                            <b-form-invalid-feedback id="inputLiveFeedback">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Rack" label-for="input-3">
                          <b-form-input
                            v-model="rack"
                            id="input-3"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="rack"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Box" label-for="input-3">
                          <b-form-input
                            v-model="box"
                            id="input-3"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            name="box"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between border-top pt-10">
                      <submit-button :loading="loading" />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listClient } from "@/api/job.api";
import { createProduct } from "@/api/warehouse.api";
import { useNotify } from "@/utils/notify";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

import { ref, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "createWarehouseProduct",
  components: { ValidationProvider, ValidationObserver },
  setup(_, context) {
    const { notify } = useNotify(context);

    const clientOptions = ref([]);

    const sku = ref("");
    const name = ref("");
    const description = ref("");
    const totalQty = ref(0);
    const rack = ref("");
    const box = ref("");
    const belongTo = ref("");

    const loading = ref(false);

    onMounted(() => {
      context.root.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      listClient().then(response => {
        const clients = response.data;

        clients.forEach(client => {
          clientOptions.value.push({ text: client.name, value: client.id });
        });
      });

      setTimeout(() => {
        // Remove page loader after some time
        context.root.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    const createNewProduct = async function() {
      loading.value = true;

      const payload = {
        sku: sku.value,
        name: name.value,
        description: description.value,
        total_qty: totalQty.value,
        rack: rack.value,
        box: box.value,
        belong_to: belongTo.value
      };

      try {
        await createProduct(payload);
        notify("Product created", `The product is created`);
        context.root.$router.push({ name: "ListWarehouseProduct" });
      } catch (e) {
        console.error(e);
      }

      loading.value = false;
    };

    return {
      sku,
      name,
      description,
      totalQty,
      rack,
      box,
      belongTo,

      clientOptions,
      createNewProduct,
      loading
    };
  }
};
</script>
